import Contact from "./pages/Contact";
import Conception from "./pages/conception";
import Home from "./pages/home";
import Realisation from "./pages/realisation";

const AppRoutes = [
  {
    index: true,
    path: "/",
    key: 1,
    title: "Acceuil",
    element: <Home />,
  },
  {
    key: 2,
    path: "/Realisation",
    title: "Realisation",
    element: <Realisation />,
  },
  {
    key: 3,
    path: "/Conception",
    title: "Conception",
    element: <Conception />,
  },
  {
    key: 3,
    path: "/Contact",
    title: "Contact",
    element: <Contact />,
  },
];

export default AppRoutes;
